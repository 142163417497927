/*###################################
##           utility classes       ##
###################################*/

.top-page-container {
  max-width: var(--rd-layout-page-width);
  width: 100%;
  margin: auto;
}

.overlay {
  background-color: var(--rd-color-common-overlay);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  z-index: 100000;
}

.overlay.expanded {
  opacity: 1;
  pointer-events: all;
}

.ant-drawer-body::-webkit-scrollbar {
  width: 4px;
}

.ant-drawer-body::-webkit-scrollbar-thumb {
  background-color: var(--rd-color-primary-blue-main);
  border-radius: 4px;
}

.ant-drawer-body::-webkit-scrollbar-track {
  background-color: var(--rd-color-background-500);
}
